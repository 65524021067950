import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Card from 'react-md/lib/Cards/Card'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { Container } from '../components/common/container'

import { FullScreenHeroSection } from '../components/common/hero-section'
import BackgroundImage from 'gatsby-background-image'

const TwitchButton = styled.a`
  background: #6441a4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 14px auto;
  width: 120px;

  &:hover {
    background: rgba(100, 65, 164, 0.9);
  }

  span {
    margin-right: 10px;
  }
`

const TextSection = styled.div`
  padding: 20px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;

  p {
    max-width: 400px;

    &:first-child {
      margin-bottom: 42px;
    }
  }
`

const BetaCard = styled(Card)`
  color: #111;
  text-shadow: none;
  padding: 16px;
  margin-top: 32px;

  p {
    color: #111;
  }
`

const BetaPage = props => {
  const {
    data: {
      heroImage,
      twitchBrandImage,
      site: {
        siteMetadata: { betaTwitchClientId, betaTwitchRedirectUrl },
      },
    },
  } = props

  const twitchUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${betaTwitchClientId}&scope=user:read:email&response_type=code&redirect_uri=${betaTwitchRedirectUrl}`

  return (
    <Layout>
      <SEO title="Beta Sign-up | StreamParrot" />
      <BackgroundImage
        fluid={{
          base64: heroImage.childImageSharp.lowRes.dataURI,
          src: heroImage.childImageSharp.hiRes.dataURI,
        }}
      >
        <FullScreenHeroSection>
          <h1>Sign-up for the Beta</h1>
          <BetaCard>
            <p>Get notified when the Beta goes live.</p>
            <p>
              Closed Beta will be limited to a select number of people from this
              list. Open Beta will follow closely after.
            </p>
            <TwitchButton
              raised
              primary
              className="md-btn md-btn--raised md-btn--text md-pointer--hover md-background--primary md-background--primary-hover md-inline-block"
              href={twitchUrl}
            >
              <span>Sign-up</span>
              <Img fixed={{ ...twitchBrandImage.childImageSharp.fixed }} />
            </TwitchButton>
            <p>
              If you don't want to join a mailing list, you are welcome to join
              us in the Discord. All announcements will be made there as well.
            </p>
          </BetaCard>
        </FullScreenHeroSection>
      </BackgroundImage>
    </Layout>
  )
}

export default BetaPage

export const pageQuery = graphql`
  query($SQIP_HERO_SHAPE_COUNT: Int, $SQIP_THUMB_SHAPE_COUNT: Int) {
    heroImage: file(relativePath: { regex: "/parrot-feathers.jpeg/" }) {
      ...HeroImage
    }
    twitchBrandImage: file(relativePath: { regex: "/twitch-icon-white.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 16) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site(siteMetadata: {}) {
      siteMetadata {
        betaTwitchRedirectUrl
        betaTwitchClientId
      }
    }
  }
`
